import React from 'react'

import logo from '../assets/images/logo.svg';

const Header = (props) => (
    <header id="header" className="alt">
        <span className="logo"><img src={logo} alt="" /></span>
        <h1>Boring Magic</h1>
        <p>Software so consistently magical that it's almost boring*.
        <br />
        <br />
        <small>
        *But not quite.
        </small>
        
        </p>
    </header>
)

export default Header
