import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Waypoint from 'react-waypoint'

import Layout from '../components/layout'
import Header from '../components/Header'
import Nav from '../components/Nav'
import pic01 from '../assets/images/pic01.jpg'

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stickyNav: false
    }
  }

  _handleWaypointEnter= () => {
    this.setState(() => ({ stickyNav: false }));
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }));
  }

  render() {

    return (
      <Layout>
        <Helmet title="Boring Magic" />

        <Header />

        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        >
        </Waypoint>
        <Nav sticky={this.state.stickyNav} />

        <div id="main">

          <section id="intro" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>Think better.</h2>
                </header>
                <p>We build systems that help you think.<br/>

                   They handle massive amounts of data quickly and invisibly,<br/>
                   then get out of your way<br/>
                   and present it cleanly and responsively.<br/>

                   <br/>

                   Talk is cheap.<br/>
                   <a href="http://www.allquakes.org" target="_blank">Here's an example</a>


                </p>
                <ul className="actions">
                  {/*
                  <li><Link to="/generic" className="button">Learn More</Link></li>*/}
                </ul>
              </div>
              <span className="image"><img src={pic01} alt="" /></span>
            </div>
          </section>

          <section id="first" className="main special">
            <header className="major">
              <h2>Clear Visualizations</h2>
              <p>Our visualizations are clean, clear, and versatile. They let you quickly get a birds-eye view of your data, then zoom in as required.
              To go below the graphical level, customizable and sortable data tables are fully integrated.
            </p>

            </header>

            <ul className="features">
              <li>
                <span className="icon major style1 fa-globe"></span>
                <h3>Maps</h3>
                <p>... let you zoom in and explore, to overlay data from a variety of 
                  sources, and to filter out exactly what matters.</p>
              </li>
              <li>
                <span className="icon major style3 fa-video-camera"></span>
                <h3>Animations</h3>
                <p>... allow you to visualize the time-dependences in a way that static displays never could.</p>
              </li>
              <li>
                <span className="icon major style5 fa-sliders"></span>
                <h3>Controls</h3>
                <p>... let you interact and learn. By moving a slider back and forth you can often
                  see in a moment what would have taken much more time and pain to extract from a table.
                  </p>
              </li>
            </ul>


            <footer className="major">
              <ul className="actions">
{/*
                <li><Link to="/generic" className="button">Learn More</Link></li>
*/}
              </ul>
            </footer>
          </section>

          <section id="second" className="main special">
            <header className="major">
              <h2>Analytics in the Cloud</h2>
              <p>The analytical core of our system is built with OwlCloud<sup><small>TM</small></sup> and &lambda;-Swarm<sup><small>TM</small></sup> technology.</p>
            </header>
            <ul className="statistics">
              <li className="style5">
                <span className="icon fa-cloud"></span>
                <strong>AWS</strong>cloud
              </li>
              <li className="style1">
                <span className="icon fa-code-fork"></span>
                <strong>Serverless</strong>for scale
              </li>
              <li className="style2">
                <span className="icon fa-database"></span>
                <strong>Databases</strong>working together
              </li>
{/*
              <li className="style3">
                <span className="icon fa-project-diagram"></span>
                <strong>2,048</strong> Tempus
              </li>
              <li className="style4">
                <span className="icon fa-laptop"></span>
                <strong>4,096</strong> Aliquam
              </li>
           */}
            </ul>
            <p className="content">
            Although completely hidden from the average user, our systems run in the AWS Cloud for maximum power and versatility.
            They use a 'serverless' approach - rather than being constrained to a particular piece of hardware they can scale-up almost instantly to handle massive workloads.
            Tens, hundreds, or even thousands of lightweight 'lambda-functions' can be spawned as required for a range of different tasks. Our &lambda;-Swarm<sup><small>TM</small></sup> technology
            allows these to be managed and monitored in real-time.
            Data can be imported from a range of sources and and analyzed on a continual basis.
            A variety of database query types are supported, including both SQL and no-SQL, with interleaved results managed by our OwlCloud<sup><small>TM</small></sup> systems to work together seamlessly.
            Machine-learning can be applied across a spectrum of different levels.

            </p>
            <footer className="major">
              <ul className="actions">  
                {/* <li><Link to="/generic" className="button">Learn More</Link></li>  */}
              </ul>
            </footer>
          </section>

          <section id="cta" className="main special">
            <header className="major">
              <h2>Solutions. Fast.</h2>
              <p>Our systems are built from the ground up to work together seamlessly.<br/>
                 They form robust, reusable, components that we can quickly bring to bear on<br/>
                 almost any big-data, analytic, or visualization application.<br/>
                 We can quickly and efficiently build you a customized world-class system.
                 <br/>
                 If you've got a problem, we've got a solution.
              </p>
            </header>
            <footer className="major">
              <ul className="actions">
                <li><Link to="/aboutUs" className="button special">Get Started</Link></li>
{/*
                <li><Link to="/generic" className="button">Learn More</Link></li>
*/}
              </ul>
            </footer>
          </section>

        </div>

      </Layout>
    )
  }
}

export default Index
